<template>
    <div>
        <MessageItem :item="item" />
        <div>
            <v-chip v-if="item.enabled" color="accent" class="my-2 mx-1" small>
                Enabled
            </v-chip>
            <v-chip v-else class="my-2 mx-1" color="error" small>
                Disabled
            </v-chip>
            <v-chip
                v-if="item.expiry"
                :color="expired ? 'error' : 'accent'"
                class="my-2 mx-1"
                small
            >
                <v-icon left> mdi-calendar-clock </v-icon>
                {{ localDate(item.expiry) }}
            </v-chip>
        </div>
        <div class="d-flex justify-end">
            <v-btn
                :disabled="pendingRemove"
                icon
                small
                @click="pendingRemove = true"
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn :disabled="editing" icon small @click="beginEdit">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </div>
        <div v-if="editing">
            <v-form ref="form" v-model="valid">
                <v-jsf
                    v-model="model"
                    :schema="parsedSchema"
                    :options="formOptions"
                ></v-jsf>
            </v-form>
            <v-row align="center" justify="end" class="pt-4 my-1">
                <v-btn @click="reset" class="mx-1">Cancel</v-btn>
                <v-btn @click="save" color="primary" class="mx-1">Save</v-btn>
            </v-row>
        </div>
        <v-dialog persistent v-model="pendingRemove" max-width="290">
            <v-card>
                <v-card-title class="text-h5"> Are you sure? </v-card-title>
                <v-card-text
                    >Are you sure you want to delete this Spotlight
                    item?</v-card-text
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="pendingRemove = false"> No </v-btn>
                    <v-btn text @click="remove"> Yes </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    components: {
        MessageItem: () => import('@/components/spotlight/items/MessageItem'),
    },
    props: {
        schema: {
            type: Object,
        },
        item: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        formOptions: {
            initialValidation: 'all',
            timePickerProps: {
                format: '24hr',
            },
        },
        valid: false,
        editing: false,
        pendingRemove: false,
        model: {},
        expired: false,
    }),
    beforeMount() {
        this.reset()
        this.checkExpired()
    },
    watch: {
        item: function () {
            this.checkExpired()
        },
    },
    computed: {
        parsedSchema() {
            return JSON.parse(this.schema.schema)
        },
    },
    methods: {
        checkExpired() {
            const isInThePast = (date) => {
                const expiry = new Date(date).valueOf()
                const now = new Date().valueOf()
                return expiry < now
            }
            this.expired = this.item.expiry && isInThePast(this.item.expiry)
        },
        localDate(dateString) {
            const date = new Date(dateString)
            return date.toLocaleString()
        },
        reset() {
            this.model = { ...this.item }
            this.editing = false
        },
        beginEdit() {
            this.model = { ...this.item }
            this.editing = true
        },
        save() {
            this.$emit('save', this.model)
            this.editing = false
        },
        remove() {
            this.$emit('remove')
            this.pendingRemove = false
            this.editing = false
        },
    },
}
</script>

<style scoped>
</style>
